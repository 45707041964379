.home_b1 {
    background: url("../../assets/images/background dots.png") no-repeat;
    height: 1440px;
    background-size: 100% 100%;
    width: 100%;
    padding-bottom: 78px;
}

.home_b1_content {
    padding: 12% 16px 0px;
    margin: auto;
    max-width: 960px;
    width: 100%;;
}

.home_b1_content p {
    padding-top: 15%;
    margin: auto;
    max-width: 925px;
    width: 100%;
}

.contact_us_btn_b {
    max-width: 279px;
    width: 100%;
}

.contact_us_btn_b .contact_us_btn {
    max-width: 279px;
    width: 100%;
    padding: 24px 48px;
    font-family: "Work Sans" !important;
}

.home_b1_content2 {
    margin-top: 20%;
    position: relative;
    font-family: 'Work Sans' !important;
}

.home_b1_content2 p {
    font-size: 300px;
    color: #2B2B2B;
    text-align: center;
    font-family: 'Work Sans' !important;
    line-height: 90%;
    letter-spacing: -15px;
}

.home_b1_content2 div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home_cont {
    padding: 0px 56px;
}

.home_b2 {
    margin-top: -5%;
}

.home_b2_b1 {
    max-width: 460px;
    width: 100%;
    margin-right: 24px;
}

.home_b2_b1 span, .home_b3_b2 span, .home_b4_b1 span {
    background: linear-gradient(to right, #86FF99 0%, #88C0FC 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.home_b2_b1 .contact_us_btn_b, .home_b3_b2 .contact_us_btn_b, .home_b4_b1 .contact_us_btn_b {
    max-width: 211px !important;
    width: 100%;
}

.home_b2_b1 .contact_us_btn_b .contact_us_btn, .home_b3_b2 .contact_us_btn_b .contact_us_btn, .home_b4_b1 .contact_us_btn {
    border-radius: 8px;
    backdrop-filter: blur(12px);
    padding: 20px 36px;
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%);
}

.home_b2_b1 .contact_us_btn_b .contact_us_btn:hover, .home_b3_b2 .contact_us_btn_b .contact_us_btn:hover, .home_b4_b1 .contact_us_btn:hover {
    border-radius: 8px;
    padding: 20px 36px;
    /*background: radial-gradient(130.78% 126.98% at 78.83% 100.00%, rgba(25, 184, 224, 0.60) 0%, rgba(190, 18, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(14, 455, 153, 0.60) 100%);*/
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
    transform: scale(1);
}

.home_b2_b1 .info_b, .home_b3_b2 .info_b, .home_b4_b1 .info_b {
    color: rgba(255, 255, 255, 0.70);
    line-height: normal !important;
}

.home_b2_b2 {
    max-width: 600px;
    width: 100%;
}

.home_b2_b2 img {
    width: 100%;
}

.home_b3_b1 {
    max-width: 438px;
    width: 100%;
    margin-right: 24px;
}

.home_b3_b1 img {
    width: 100%;
}

.home_b3_b2 {
    max-width: 450px;
    width: 100%;
    margin-left: 18%;
}

.home_b4_b1 {
    max-width: 450px;
    width: 100%;
    margin-right: 38px;
}

.home_b4_b2 {
    max-width: 620px;
    width: 100%;
}

.home_b4_b2 img {
    width: 100%;
}

.contact_sales_big_btn {
    width: 100%;
    max-width: 1164px;
    border-radius: 24px;
    margin-left: auto;
    margin-right: auto;
}

.contact_sales_b {
    max-width: 1164px;
    width: 100%;
}

.contact_sales_btn_b {
    margin: 20px auto;
    max-width: 1164px;
    width: 100%;
    border-radius: 24px;
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%);
    backdrop-filter: blur(12px);
    padding: 56px;
    cursor: pointer;
}

.contact_sales_btn_b:hover {
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
    transform: scale(1);
    border-radius: 24px;
}

.contact_sales_btn_b:hover .arrow_pic_white {
    padding-right: 70px;
}

