.forgot_password{
    text-decoration-line: underline !important;
    text-decoration-color: #d7b8c8;
    background: linear-gradient(to right,#FFB8E0 0%,  #BE9EFF 38.89%,  #88C0FC 67.40%, #86FF99 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
}
.sign_in_form_cont{
    max-width: 550px;
    margin: -220px auto 29px;
    width: 100%;
}