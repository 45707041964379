.privacy_cont {
    max-width: 1188px;
    padding: 0px 16px;
    margin: auto;
}

.privacy_b2_b1 ,.privacy_b4_b1 {
    max-width: 450px;
    width: 100%;
    margin-right: 24px;
}

.privacy_b2_b1 img, .privacy_b4_b1 img {
    width: 100%;
}

.privacy_b2_b2, .privacy_b4_b2 {
    max-width: 450px;
    width: 100%;
}
.privacy_b2_b2 .info_b, .privacy_b3_b1 .info_b, .privacy_b4_b2 .info_b {
    color: rgba(255, 255, 255, 0.70);
    line-height: normal;
}
.privacy_b3_b1{
    max-width: 450px;
    width: 100%;
    margin-right: 24px;
}
.privacy_b3_b2{
    max-width: 450px;
    width: 100%;
}
.privacy_b3_b2 img{
    width: 100%;
}
.contact_us_btn_b_small{
    max-width: 211px;
    width: 100%;
}
.contact_us_btn_small{
    padding: 20px 36px;
    white-space: nowrap;
}