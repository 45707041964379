.webapp_user_back {
    background: url("../../../assets/images/background dots.png");
    width: 100%;
    height: 100vh;
}

.WepAppUserHome_cont {
    padding: 90px 24px 0px;
    margin-left: 20%;
    max-width: 1430px;
    width: 100%;
}

.webapp_user_header {
    width: 100%;
    border-radius: 16px;
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%);
    backdrop-filter: blur(12px);
    padding: 24px 32px;
    cursor: pointer;
}

.webapp_user_header_b1 {
    width: 100%;
}

.webapp_user_header_b1 input {
    max-width: 1123px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    padding: 16px;
    color: #282c34;
    font-size: 16px;
}

.webapp_user_header_b1 input::placeholder {
    color: #424242;
}

.webapp_user_header_b1 input:before {
    background: red;
    width: 30px;
    height: 30px;
    content: url("../../../assets/images/icons/Search_icon.png");
}

.search_btn {
    width: 99px;
    height: 56px;
    margin-left: 18px;
    border-radius: 8px;
    border: 1px solid #AFAFB8;
}

.search_btn:hover {
    font-size: 16px;

}
