.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: linear-gradient(rgba(17, 17, 17, 0.80) 0%, rgba(17, 17, 17, 0.80) 100%) !important;
}

.ReactModal__Content {
    border-radius: 16px !important;
    background: linear-gradient(to right, #FFB8E0, #BE9EFF, #88C0FC, #86FF99) !important;
    padding: 0.3px !important;
}

.modal_content {
    padding: 32px;
    width: 100%;
    position: relative;
    background: #232323 !important;
  border-radius: 16px;
}
.close_icon{
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
}
.info_email_text{
    max-width: 352px;
    width: 100%;
    margin-top: 8px;
}