.policy_cont {
    margin: 166px auto 96px;
    max-width: 752px;
    width: 100%;
    padding: 0px 16px;
}

.policy_cont div {
    color: #667085;
}

.policy_cont h2 {
    color: #101828;
}

.current_date_text {
    margin-bottom: 12px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

