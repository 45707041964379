.user_info_cont {
    max-width: 1300px;
    width: 100%;
    padding: 24px;
    gap: 24px;
    border-radius: 15px;
    background: #1A1A1A;
}

.admin_pic, .admin_pic img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
}

.upload_delete_pic_btns_b {
    margin-top: 4px;
}

.upload_delete_pic_btns_b {
    background: linear-gradient(to right, #FFB8E0 0%, #BE9EFF 10.89%, #88C0FC 77.40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
}

.upload_delete_pic_btns_b button:first-child {
    margin-right: 5px;
}

.upload_delete_pic_btns_b button:last-child {
    margin-left: 5px;
}

.user_info_cont_b1 {
    max-width: 460px;
    width: 100%;
}

.user_info_cont_b1_inputs_b {
    width: 100%;
}

.user_info_cont_b1_inputs_b .user_info_cont_b1_input_b {
    width: 100%;
}

.user_info_cont_b1_inputs_b .user_info_cont_b1_input_b label {
    margin-bottom: 6px;
    color: rgba(175, 175, 184, 1);

}

.user_info_cont_b1_inputs_b .user_info_cont_b1_input_b input {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #363636;
    padding: 8px 12px;
    width: 100%;
}

.cancel_save_btn .save_btn_b, .cancel_save_btn .cancel_btn {
    width: 83px;
}

.cancel_save_btn .save_btn, .cancel_save_btn .cancel_btn {
    padding: 8px 16px;
    width: 100%;
}
 .cancel_save_btn .cancel_btn_b:hover {
transform: scale(1.03);
}