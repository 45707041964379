.sidebar {
    position: fixed;
    background: transparent;
    max-width: 250px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 999;
}

.sidebar_logo {
    padding: 22px 24px;
}

.library_btn_b {
    margin: 22px auto 0px;
    width: 100%;
    max-width: 230px;

}

.library_btn {
    width: 100%;
    padding: 12px 16px;
}

.library_btn img {
    margin-right: 14px;
}

.sidebar_navlinks_b {
    margin-top: 20px;
    min-height: 720px;
    height: 100%;

}

.sidebar_navLink, .sidebar_navLink_active {
    display: flex;
    align-items: center;
    padding: 14px 12px 14px 24px;
}

.sidebar_navLink {
    color: #92929D;
}

.sidebar_navLink:first-child:before {
    content: url("../../assets/images/icons/webchat_not_active.png");
    margin-right: 14px;
}

.sidebar_navLink_active:first-child:before {
    content: url("../../assets/images/icons/webchat_active.png");
    margin-right: 14px;
}

.sidebar_navLink:nth-child(2):before {
    content: url("../../assets/images/icons/voice_chat_icon.png");
    margin-right: 14px;
}

.sidebar_navLink_active:first-child(2):before {
    content: url("../../assets/images/icons/voice_chat_active.png");
    margin-right: 14px;
}

.sidebar_navLink:nth-child(3):before {
    content: url("../../assets/images/icons/ImageChat_icon.png");
    margin-right: 14px;
}

.sidebar_navLink_active:first-child(3):before {
    content: url("../../assets/images/icons/ImageChat_active.png");
    margin-right: 14px;
}

.sidebar_navLink:nth-child(4):before {
    content: url("../../assets/images/icons/videChat_icon.png");
    margin-right: 14px;
}

.sidebar_navLink_active:first-child(4):before {
    /*content: url("../../assets/images/icons/videchat_active.png");*/
    margin-right: 14px;
}

.sidebar_navLink:nth-child(5):before {
    content: url("../../assets/images/icons/favourite_icon.png");
    margin-right: 14px;
}

.sidebar_navLink_active:first-child(5):before {
    /*content: url("../../assets/images/icons/favourite_active.png");*/
    margin-right: 14px;
}

.sidebar_navLink_active {
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
}

.sidebar_navLink_active:before {
    content: url("../../assets/images/Selector.png");
}

.sidebar_navLink:hover, .sidebar_navLink_active:hover {
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
}

.sidebar_navLink_active {
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
}

.sidebar_navLink img, .sidebar_navLink_active img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
}

.sidebar_navlinks_b2 {
    max-width: 210px;
    width: 100%;
    border-top: 2px solid rgba(255, 255, 255, 0.20);
    margin: auto;
    padding-top: 15px;
    cursor: pointer;
}

.sidebar_navlinks_b2_item {
    width: 100%;
    padding: 10px 0px;
    color: #92929D;
}

.sidebar_navlinks_b2_item .arrow {
    margin-left: 16px;
}

.sidebar_navlinks_b2_item_b {
    width: 100%;
    max-width: 200px;
    margin: auto;
    color: #696974;
}

.sidebar_navlinks_b2_item_b span {
    margin-right: 5px;
}

.circle_purple, .circle_green, .circle_blue, .circle_yellow {
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.circle_purple {
    background: #A461D8;
}

.circle_green {
    background: #85C545;
}

.circle_blue {
    background: #50B5FF;
}

.circle_yellow {
    background: #FFC542;
}

.sidebar_navLink span, .sidebar_navLink_active span {
    width: 36px;
    background: white;
    border-radius: 12px;
    padding: 3px 6px;
    margin-left: 72px;
    color: black;
    display: flex;
    justify-content: center;
}