.Admin_cont{
    margin-left: 30%;
    padding-top: 4.8%;
}
.admin_tabs{
    position: fixed;
    left: 15%;
    top: 10%;
}
.button_tabs{
    width: 256px;
   height: 100%;
    background:  #1A1A1A;
    padding: 12px 24px;
    border-radius: 15px;
}
.btn_main{
    color: #92929D;
    padding: 12px;
    cursor: pointer;
}
.btn_main_active{
    color: white;
}
.btn_main:hover{
    color: white;
}
.admin_profile_cont{

}