.company_profile_cont{
    max-width: 1300px;
    width: 100%;
    padding: 24px;
    gap: 24px;
    border-radius: 15px;
    background: #1A1A1A;
}
.company_profile_cont_b1{
    max-width: 468px;
    width: 100%;
}
.inputs_span_colorful{
    text-decoration-line: underline !important;
    text-decoration-color:rgba(190, 158, 255, 1);
    background: linear-gradient(to right, #FFB8E0 0%, #BE9EFF 10.89%, #88C0FC 77.40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
    margin-top: 14px;
}
.company_profile_cont_b1_input_b label{
    margin-bottom: 14px !important;
}