.header_cont {
    padding: 16px 32px;
    position: fixed;
    z-index: 998;
    width: 100%;
}
.logo{
    cursor: pointer;
}

.header_navlinks_cont {

}
.header_navLink{
 font-weight: 600;
}
.header_navLink, .header_navLink_active {
    margin-right: 40px;
    color: white;
}
.header_navLink_active {
    color: #ABABAB;
}

.header_navLink:hover, .header_navLink_active:hover {
    font-weight: 700;
    transform: scale(1.08);
    color: #ABABAB;
}
.login_register_btn_block{
}

.contact_us_btn, .contact_us_btn_white {
    padding: 6px 12px;
    border-radius: 8px;
}

.contact_us_btn:hover {
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
    transform: scale(1);
    border-radius: 8px;
}
.login_btn {
  width: 103px;
}
.search_b{
    margin-right: 24px;
}
.profile_b{
    margin-left: 24px;
    cursor: pointer;
}
.profile_b1{
    margin-right: 10px;
}
.profile_b1 img{
    margin-right: 10px;
}