.footer_cont{
    font-family:' DMSans' !important;
    background: transparent;
    padding: 60px 118px;
}
.footer_cont_grey{
    font-family:' DMSans' !important;
    background: rgba(217, 217, 217, 0.10) !important;
    padding: 60px 118px;
}
.footer_b1{
    padding-bottom: 31px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.76);
}
.footer_b2{
    padding-top: 47px;
}
.footer_navLink, .footer_navLink_active{
    margin-right: 29px;
    color: white;
}
.footer_navLink_active{
  font-weight: 600;
}
.footer_navLink:hover, .footer_navLink_active:hover {
    font-weight: 700;
    transform: scale(1.08);
}
.social_block a{
   margin-right: 24px;
}
.social_block a:last-child{
    margin-right: 0px;
}
