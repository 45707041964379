.contact_sales_cont {
    padding: 32px;
}

.contact_sales_cont_b1 {
    position: relative;
    max-width: 656px;
    width: 100%;
    height: 100%;
    margin-right: 32px;
}

.contact_sales_pic1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact_sales_back_pic {
    height: 100%;
}

.contact_sales_cont_b2 {
    max-width: 720px;
    width: 100%;
    min-height: 836px;
    height: 100%;
}

.title {
    background: linear-gradient(to right, rgba(137, 180, 180, 0.6) 0%, #BE9EFF99 25%, #88C0FC99 50%, rgba(201, 136, 182, 0.45) 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
}

.contact_sales {
    margin-top: 8px;
}

.contact_sales a {
    text-decoration-line: underline !important;
    text-decoration-color: #d7b8c8;
    background: linear-gradient(to right, #FFB8E0 0%, #BE9EFF 10.89%, #88C0FC 77.40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}

.contact_sales_form_cont {
    /*position: absolute;*/
    /*top: 13%;*/
    /*left: 50%;*/
    /*transform: translate(18%, 0%);*/
    max-width: 550px;
    margin: -45px auto 29px;
    width: 100%;
}

.border_wrap {
    background: linear-gradient(to right, #FFB8E0, #BE9EFF, #88C0FC, #86FF99);
    padding: 1px;
    border-radius: 16px;
    margin-bottom: 10px;
}

.contact_sales_form_cont_block {
    border-radius: 16px;
    background: #232323;
    padding: 32px;
}

.contact_sales_form_cont_block .close_icon {
    position: absolute;
    top: 32px;
    right: 32px;
}

.contact_sales_form_cont_item label {
    margin-bottom: 6px;
}

.contact_sales_form_cont_item input {
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #363636;
    height: 37px;
}
.contact_sales_form_cont_item input:focus {
    /*background: linear-gradient(to right, #FFB8E0, #BE9EFF, #88C0FC, #86FF99);*/
    /*padding: 1px;*/
    /*border-radius: 16px;*/
    /*margin-bottom: 10px;*/
    border: 1px solid green;
}


.contact_sales_form_cont_item textarea {
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #363636;
    height: 37px;
}

.help_icon {
    margin-right: 5px;
}

.border_wrap_send_btn {
    /*background: linear-gradient(to right, #86FF99, #88C0FC, #FFB8E0, #BE9EFF);*/
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.40%, #86FF99 100%);
    padding: 2px;
    border-radius: 8px;
}

.border_wrap_send_btn_inner {
    width: 100%;
    border-radius: 8px;
    background: #282c34;
    height: 100%;
}

.send_btn_b {
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%);
    padding: 16px 24px;
    margin: auto;
    cursor: pointer;
}

.send_btn_b:hover {
    transform: scale(1);
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%);
}

.send_btn_b:hover .send_btn {
    font-weight: 700;
}