.subscribe_enterprise_b1{
    background: #1A1A1A;
    padding: 32px 24px;
}
.modal_content_{
    /*padding: 32px 24px;*/
    width: 100%;
    position: relative;
    /*background: #232323 !important;*/
    border-radius: 16px;
}
.subscribe_enterprise_b2{
    background:  #232323;
    padding: 24px 24px 45px 40px;
    max-width: 466px;
    width: 100%;
}
.ReactModal__Content--after-open{
    max-width: max-content !important;
}
.number_users_btn_b{
    margin-top: 8px;
}
.number_users_btn{
    border-radius: 8px;
    border: 1px solid  #363636;
    padding: 11px 16px;
    background: #232323;
}
.number_users_btn_b .button_border{
    margin-right: 16px;
}
.number_users_btn_b .right_16{
    margin-right: 16px;
}
.billing_plan_btns_b{
    margin-top: 12px;
    grid-row-gap: 20px;
    grid-column-gap: 16px;
}
.billing_plan_btn{
    border-radius: 8px;
    border: 1px solid  #363636;
    padding: 11px;
    background: #232323;
    width: 193px;
}
.billing_plan_btn p{
    margin-bottom: 8px;
}
.billing_plan_btn span {
    margin-left: 8px;
}
.continue_bt_b{
   padding: 10px 12px;
}
.subscribe_now_btn{
    border-radius: 8px;
    background: #232323;

    width: 287px;
}
.subscribe_now_btn_inner{
    padding: 8px 12px;
    border-radius: 8px;
    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%);
}
.subscribe_now_btn .total_price{
    margin-left: 16px;
}

