.subcription_cont{
    max-width: 733px;
    width: 100%;
}
.subcription_cont_item {
    padding: 24px;
    border-radius: 16px;
    background: #232323;
    /*max-width: 633px;*/
    width: 100%;
}

.text_colorful {
    background: linear-gradient(to right, #FFB8E0 0%, #BE9EFF 10.89%, #88C0FC 77.40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
}

.subcription_cont_item_header_p1 .text_colorful {
    margin-left: 5px;
}

.subcription_cont_item_header .cancel_save_btn .cancel_btn {
    padding: 12px 24px !important;
}

.subcription_cont_item_b1 {
    margin-top: 19px;
}
.subcription_cont_item_b1_item {
    margin-right: 24px;
}

.subcription_cont_item_b1_item div {
    max-width: 133px;
    width: 100%;
    margin-left: 8px;
}

.subcription_cont_item_b1_item  div span {
  color: #92929D;
}
.edit_users img{
    margin-right: 8px;
}
.included_plan_div{
    margin-top: 40px;
}
.included_plan_div p{
    margin-bottom: 8px;
    text-transform: uppercase;
}
.included_plan_div div{
    margin-bottom: 2px;
}
.included_plan_div div img{
    margin-right: 5px;
}
.upgrate_plan_b button{
    padding: 12px 24px;
}
.subcription_cont_item button{
    padding: 12px 24px !important;
}