@font-face {
    font-family: 'Poppins';
    src: url('../src/assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'DMSans';
    src: url('../src/assets/fonts/DM_Sans/DMSans-Regular.ttf');
}

@font-face {
    font-family: 'Work Sans';
    src: url('../src/assets/fonts/Work_Sans/WorkSans-Regular.ttf');
}
@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Rubik';
    src: url('../src/assets/fonts/Rubik/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url('../src/assets/fonts/Roboto/Roboto-Regular.ttf');
}

.font_DMSans {
    font-family: 'DMSans' !important;
}

.font_WorkSans {
    font-family: 'Work Sans' !important;
}
.font_Inter {
    font-family: 'Inter' !important;
}
.font_Rubik {
    font-family: 'Rubik' !important;
}
.font_Roboto {
    font-family: 'Roboto' !important;
}

body {
    background: linear-gradient(0deg, #0D0D0D 0%, #0D0D0D 100%), lightgray 50% / cover no-repeat;
    width: 100%;
    margin: auto;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    box-sizing: border-box;
    border: none;
    outline: none;
    color: white;
}

button {
    cursor: pointer;
    border: none;
    background: transparent;
}

.text_capitalize {
    text-transform: capitalize;
}

.container {
    max-width: 1848px;
    width: 100%;
    margin: 0 auto;
}

.container_dashboard {
    max-width: 1429px;
    width: 100%;
    margin: 0 auto;
}

.container_inner {
    padding: 0px 16px;
}

.d_grid {
    display: grid;
    align-items: center;
    justify-items: center;
}

.grid_columns_4fr {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid_columns_3fr {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid_columns_2fr {
    grid-template-columns: 1fr 1fr;
}

.grid_gab_35 {
    grid-gap: 35px;
}

.grid_gab_50 {
    grid-gap: 50px;
}

.d_flex {
    display: flex;
}

.fd_row {
    flex-direction: row;
}

.fd_column {
    flex-direction: column;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_end {
    justify-content: flex-end;
}

.justify_content_start {
    justify-content: start;
}

.justify_content_space_between {
    justify-content: space-between;
}

.align_items_center {
    align-items: center;
}

.align_items_start {
    align-items: flex-start;
}

.align_items_end {
    align-items: flex-end;
}

.f_400 {
    font-weight: 400;
}

.f_500 {
    font-weight: 500;
}

.f_600 {
    font-weight: 600;
}

.f_700 {
    font-weight: 700;
}

.fs_10 {
    font-size: 10px;
    line-height: 16px;
}

.fs_12 {
    font-size: 12px;
    line-height: 12px;
}

.fs_14 {
    font-size: 14px;
    line-height: normal;
}

.fs_16 {
    font-size: 16px;
    line-height: 26px;
}

.fs_18 {
    font-size: 18px;
    line-height: 27px;
}

.fs_20 {
    font-size: 20px;
    line-height: 28px;
}

.fs_24 {
    font-size: 24px;
    line-height: 90%;
}

.fs_28 {
    font-size: 28px;
    line-height: 36px;
}

.fs_32 {
    font-size: 32px;
    line-height: 39px;
}

.fs_36 {
    font-size: 36px;
    line-height: 44px;
}

.fs_38 {
    font-size: 38px;
    line-height: 46px;
}

.fs_40 {
    font-size: 40px;
    line-height: normal;
}
.fs_42 {
    font-size: 40px;
    line-height: 110%;
}

.fs_48 {
    font-size: 48px;
    line-height: 90%;
}


.fs_80 {
    font-size: 80px;
    line-height: normal;
}

.fd_96 {
    font-size: 96px;
    line-height: 90%;
}

.c_white {
    color: #FFFFFF;
}

.c_black {
    color: #3C3C3C;
}

.c_purple {
    color: #7F56D9;
}

.c_lightGrey {
    color: #C8C8C8;
}

.c_grey {
    color:#6D6D6D;
}

.c_red {
    color: red;
}
.c_colorful{

}

.c_green {
    color: #49D69C;
}

.bc_white {
    background: white;
}

.margin_top_16 {
    margin-top: 16px !important;
}
.margin_top_24 {
    margin-top: 24px !important;
}
.margin_top_32 {
    margin-top: 32px;
}

.margin_top_48 {
    margin-top: 48px;
}

.margin_top_64 {
    margin-top: 64px;
}
.margin_top_96 {
    margin-top: 96px;
}
.margin_top_140 {
    margin-top: 140px;
}
.margin_bottom_150 {
    margin-bottom: 150px;
}


.bc_green {
    background: #49D69C;
}

.bc_purple {
    background: #B9B9FF;
}

.bc_colorful {
    border-radius: 8px;

    background: radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.60) 0%, rgba(190, 158, 255, 0.60) 38.89%, rgba(136, 192, 252, 0.60) 67.40%, rgba(134, 255, 153, 0.60) 100%) !important;
}
.bc_colorful:hover {
    border-radius: 8px;
    background:radial-gradient(130.72% 126.98% at 72.83% 100.00%, rgba(255, 184, 224, 0.20) 0%, rgba(190, 158, 255, 0.20) 38.89%, rgba(136, 192, 252, 0.20) 67.40%, rgba(134, 255, 153, 0.20) 100%) !important;
}
.bc_black {
    /*background: linear-gradient(0deg, #0D0D0D 0%, rgb(0, 0, 0) 100%), rgb(238, 240, 243) 20% / cover no-repeat;*/
    background: #000000;
}

input {
    background: none;
}

input:focus {
    outline: none;
}

a {
    text-decoration: none;
}

.text_center {
    text-align: center;
}

.text_left {
    text-align: left;
}

.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.error {
    color: red;
    font-size: 16px;
    margin-bottom: 8px;
    text-align: center;
}

::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #787878;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #424242;
}
.button_border{
    border-radius: 8px;
    padding: 1px;
    width: max-content;
    background: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
}
.button_border_back{
    background: black;
    margin: 1px;
    border-radius: 8px;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media only screen and (min-width: 1440px) {
    .container_big_sizes {
        max-width:1440px;
        margin: 0 auto;
        width:100%;
    }
}