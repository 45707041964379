.reset_info_text{

}



.reset_info_text span{
    text-decoration-line: underline !important;
    text-decoration-color: #d7b8c8;
    background: linear-gradient(to right, #FFB8E0 0%, #BE9EFF 10.89%, #88C0FC 77.40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
}
.or_line div{
    background: linear-gradient(to right,  #86FF99 0%, #88C0FC  15.89%, #BE9EFF 77.40%,  #FFB8E0 100%);
    width: 45%;
    height: 1px;
}